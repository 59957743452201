// LEGACY
import cx from 'classnames';

const PageTitle = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => (
  <>
    <h1
      className={cx(
        'font-bold',
        'text-base',
        'lg:text-2xl',
        'leading-6',
        'lg:leading-8',
        ''
      )}
    >
      {title}
    </h1>
    {subtitle && <h2 className={cx('text-base', '')}>{subtitle}</h2>}
  </>
);

export default PageTitle;
