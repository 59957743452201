import cx from 'classnames';
import { atom, useAtom } from 'jotai';
import { Router } from 'next/router';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';

import Button from '../../ui/button';
import BPartsBicon from '../../ui/icons/bparts-b-icon';
import IconClose from '../../ui/icons/close';
import IconDanger from '../../ui/icons/danger';
import IconSuccess from '../../ui/icons/success';
import IconWarning from '../../ui/icons/warning';

import classes from './alert.module.css';

type TVariants = 'PRIMARY' | 'SUCCESS' | 'WARNING' | 'DANGER';

const getVariantClass = (variant: TVariants) => {
  switch (variant) {
    case 'PRIMARY':
      return cx('bg-blue-10');
    case 'SUCCESS':
      return cx('bg-green-20');
    case 'WARNING':
      return cx('bg-orange-10');
    case 'DANGER':
      return cx('bg-red-10');
    default:
      return cx('bg-blue-10');
  }
};

const getVariantLoaderClass = (variant: TVariants) => {
  switch (variant) {
    case 'PRIMARY':
      return cx('bg-bparts-100');
    case 'SUCCESS':
      return cx('bg-green-100');
    case 'WARNING':
      return cx('bg-orange-100');
    case 'DANGER':
      return cx('bg-red-100');
    default:
      return cx('bg-bparts-100');
  }
};

const getVariantIcon = (variant: TVariants) => {
  switch (variant) {
    case 'PRIMARY':
      return <BPartsBicon className={cx('h-7')} />;
    case 'SUCCESS':
      return <IconSuccess className={cx('h-6', 'text-green-100')} />;
    case 'WARNING':
      return <IconWarning className={cx('h-6', 'text-orange-100')} />;
    case 'DANGER':
      return <IconDanger className={cx('h-6', 'text-red-100')} />;
    default:
      return <BPartsBicon className={cx('h-7')} />;
  }
};

const alerts = atom<Map<string, TAlert>>(new Map());

export const addAlert = atom(null, (get, set, alert: TAlert) => {
  const currentAlerts = new Map(get(alerts));
  // set new alert
  currentAlerts.set(alert.id, alert);
  set(alerts, currentAlerts);

  if (alert.autoClose) {
    setTimeout(() => {
      // delete new alert
      const currentAlerts = new Map(get(alerts));
      currentAlerts.delete(alert.id);
      set(alerts, currentAlerts);
    }, 4000);
  }
});

export const useAddAlert = () => {
  const [, setAlert] = useAtom(addAlert);

  return useCallback(
    (alert) => {
      setAlert(alert);
    },
    [setAlert]
  );
};

export const removeAlert = atom(null, (get, set, id: string) => {
  const currentAlerts = new Map(get(alerts));
  // delete
  currentAlerts.delete(id);
  set(alerts, currentAlerts);
});

export const useRemoveAlert = () => {
  const [, delAlert] = useAtom(removeAlert);

  return useCallback(
    (id) => {
      delAlert(id);
    },
    [delAlert]
  );
};

export const removeAllAlertsAtom = atom(null, (get, set) => {
  set(alerts, new Map());
});

const LoadingBar: FunctionComponent<{
  color: string;
}> = ({ color }) => {
  return (
    <div
      className={cx(
        classes.barContainerCSS,
        'absolute',
        'top-0',
        'left-0',
        'w-full'
      )}
    >
      <div className={cx(classes.bar, color)} />
    </div>
  );
};

const Alerts: FunctionComponent = () => {
  const [currentAlerts] = useAtom(alerts);
  const [, onClose] = useAtom(removeAlert);
  const [, removeAllAlerts] = useAtom(removeAllAlertsAtom);

  useEffect(() => {
    Router.events.on('routeChangeStart', removeAllAlerts);

    return () => {
      Router.events.off('routeChangeStart', removeAllAlerts);
    };
  }, []);

  return (
    <div className={cx(classes.alerts, 'fixed', 'container')}>
      <TransitionGroup>
        {Array.from(currentAlerts).map(([id, alert]) => {
          const variant = alert.type ? alert.type : 'PRIMARY';
          return (
            <Transition key={id} mountOnEnter unmountOnExit timeout={300}>
              {(state) => (
                <div
                  className={cx(
                    (state === 'entering' || state === 'entered') &&
                      classes.animationIn,
                    (state === 'exiting' || state === 'exited') &&
                      classes.animationOut,
                    'rounded-b',
                    'pl-4',
                    'py-2',
                    'pr-2',
                    'mb-4',
                    'shadow-md',
                    'flex',
                    'justify-between',
                    [getVariantClass(variant)]
                  )}
                  role="alert"
                >
                  {alert.autoClose &&
                    (state === 'entering' ||
                      state === 'entered' ||
                      state === 'exiting' ||
                      state === 'exited') && (
                      <LoadingBar color={getVariantLoaderClass(variant)} />
                    )}

                  <div className="flex items-center">
                    {getVariantIcon(variant)}
                    {alert.title && (
                      <h3 className="pl-4 pb-2 font-bold">{alert.title}</h3>
                    )}
                    <span>
                      <p className="pl-4 py-2 text-sm">{alert.text}</p>
                      {alert.items && (
                        <ul className="pl-12 text-xs list-disc">
                          {alert.items.map((item, i) => (
                            <li key={`it-${alert.id}-${i}`}>{item}</li>
                          ))}
                        </ul>
                      )}
                    </span>
                  </div>

                  <Button onClick={() => onClose(alert.id)} isFullWidth={false}>
                    <IconClose width={16} className="text-neutral-80" />
                  </Button>
                </div>
              )}
            </Transition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default Alerts;
