import { FunctionComponent, HTMLAttributes } from 'react';

const IconDanger: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58885 0 0 3.58885 0 8C0 12.4112 3.58885 16 8 16C12.4112 16 16 12.4112 16 8C16 3.58885 12.4112 0 8 0ZM8 12.3042C7.84786 12.3042 7.69914 12.2591 7.57264 12.1746C7.44614 12.0901 7.34755 11.9699 7.28932 11.8294C7.2311 11.6888 7.21587 11.5341 7.24555 11.3849C7.27523 11.2357 7.34849 11.0987 7.45607 10.9911C7.56365 10.8835 7.70071 10.8102 7.84993 10.7806C7.99915 10.7509 8.15381 10.7661 8.29437 10.8243C8.43493 10.8825 8.55507 10.9811 8.63959 11.1076C8.72412 11.2341 8.76923 11.3829 8.76923 11.535C8.76923 11.739 8.68819 11.9347 8.54393 12.0789C8.39967 12.2232 8.20401 12.3042 8 12.3042ZM8.83539 4.56769L8.61462 9.26C8.61462 9.42321 8.54978 9.57974 8.43437 9.69514C8.31897 9.81055 8.16244 9.87539 7.99923 9.87539C7.83602 9.87539 7.6795 9.81055 7.56409 9.69514C7.44868 9.57974 7.38385 9.42321 7.38385 9.26L7.16308 4.57V4.56808C7.15824 4.45536 7.17626 4.34282 7.21606 4.23725C7.25586 4.13168 7.31662 4.03526 7.39467 3.95379C7.47272 3.87231 7.56645 3.80748 7.67021 3.76319C7.77398 3.7189 7.88564 3.69606 7.99846 3.69606C8.11129 3.69606 8.22294 3.7189 8.32671 3.76319C8.43048 3.80748 8.52421 3.87231 8.60226 3.95379C8.68031 4.03526 8.74106 4.13168 8.78086 4.23725C8.82066 4.34282 8.83869 4.45536 8.83385 4.56808L8.83539 4.56769Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconDanger;
