// LEGACY
import cx from 'classnames';
import Link from 'next/link';
import { FunctionComponent } from 'react';

import FeatureLink from '../../ui/links/featured';

import classes from './breadcrumbs.module.css';

const BCItem: FunctionComponent<BreadCrumbItemProps> = ({
  text,
  onClick,
  ...linkProps
}) => {
  return (
  <div
    className={cx(
      [classes.breadcrumbsItem],
      'flex',
      'items-center',
      'shrink-0',
      'pt-4',
      'pb-3',
      'text-sm'
    )}
  >
    <Link {...linkProps} passHref shallow legacyBehavior >
      <FeatureLink
        variant="neutral"
        className={cx('text-neutral-80')}
        title={text}
        onClick={onClick}
      >
        {text}
      </FeatureLink>
    </Link>
  </div>
)};

export default BCItem;
