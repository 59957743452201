import { HTMLAttributes, FunctionComponent } from 'react';

const BPartsBicon: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = ({
  title,
  ...props
}) => (
  <svg
    {...props}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M24.5993 3.55223C31.921 1.59039 39.4468 5.93541 41.4086 13.2571L44.4478 24.5993C46.4096 31.921 42.0646 39.4468 34.7429 41.4086L23.4007 44.4478C16.079 46.4096 8.5532 42.0646 6.59136 34.7429L3.55223 23.4007C1.59039 16.079 5.93541 8.55321 13.2571 6.59136L24.5993 3.55223Z"
      fill="#359AD2"
    />
    <path
      d="M18.98 24.0573C19.1572 23.6776 19.4215 23.3467 19.7471 23.0902C20.197 22.7357 20.7641 22.5234 21.3807 22.5208H21.7149V22.0781L24.2834 23.5974C24.3919 23.6616 24.3919 23.8187 24.2834 23.8829L21.7149 25.4023V24.9732H21.5882C21.405 24.9732 21.2565 25.1217 21.2565 25.3049V29.8208H26.073C26.8731 29.8208 27.4706 29.5974 27.8655 29.1506C28.2396 28.7245 28.4266 28.1582 28.4266 27.4516C28.4266 26.762 28.2238 26.1912 27.8181 25.7392C28.2987 25.5652 28.7094 25.3198 29.1537 25.0043C29.4072 24.8256 29.6404 24.6283 29.853 24.4129C30.0716 24.6778 30.2612 24.9685 30.4218 25.285C30.7751 25.9708 30.9518 26.693 30.9518 27.4516C30.9518 28.3037 30.7543 29.0934 30.3594 29.8208C29.975 30.5482 29.4034 31.1458 28.6449 31.6134C27.8863 32.0706 27.0134 32.2992 26.0262 32.2992H18.7314V25.181H18.7317C18.7317 24.7795 18.8207 24.3987 18.98 24.0573Z"
      fill="white"
    />
    <path
      d="M27.1539 22.5481V22.9784C27.3921 22.8609 27.6033 22.7037 27.7876 22.507C28.2136 22.0497 28.4266 21.4678 28.4266 20.7612C28.4266 20.0546 28.2396 19.4882 27.8655 19.0622C27.4706 18.6154 26.8731 18.3919 26.073 18.3919H21.2565V22.0804C20.2135 22.1251 19.3039 22.6847 18.7753 23.5114C18.7466 23.3767 18.7314 23.2365 18.7314 23.0919V15.9136H26.0262C27.0134 15.9136 27.8863 16.1422 28.6449 16.5994C29.4034 17.067 29.975 17.6645 30.3594 18.3919C30.7543 19.1193 30.9518 19.9091 30.9518 20.7612C30.9518 21.5198 30.7751 22.242 30.4218 22.9278C30.3396 23.0898 30.2498 23.245 30.1524 23.3935C29.9591 23.666 29.7337 23.9232 29.4905 24.1545C29.4424 24.2003 29.3935 24.2451 29.3441 24.2887C29.1994 24.4167 29.0496 24.5353 28.8976 24.6424C28.8413 24.6824 28.7858 24.721 28.731 24.7582C28.2163 25.1079 27.7585 25.3363 27.1539 25.4703V25.8723L24.5854 24.3529C24.4769 24.2887 24.4769 24.1317 24.5854 24.0675L27.1539 22.5481Z"
      fill="white"
    />
  </svg>
);

export default BPartsBicon;
