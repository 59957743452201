import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import useLinkProps from '../../../hooks/use-link-props';
import FeaturedLink from '../../ui/links/featured';

const HelpContacts = () => {
  const { t } = useTranslation();
  const { locale = 'en' } = useRouter();
  const getLink = useLinkProps();

  return (
    <aside className='flex flex-row gap-2'>
      <h2 className={cx('font-bold', 'tracking-wide')}>
        {t('help:have-doubts')}
      </h2>
      <Link {...getLink('contacts', undefined)} passHref legacyBehavior>
        <FeaturedLink className="">
          {t('help:contact_link')}
        </FeaturedLink>
      </Link>
    </aside>
  );
};

export default HelpContacts;
