import { FunctionComponent, HTMLAttributes } from 'react';

const IconSuccess: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58885 0 0 3.58885 0 8C0 12.4112 3.58885 16 8 16C12.4112 16 16 12.4112 16 8C16 3.58885 12.4112 0 8 0ZM12.1635 5.31885L6.99423 11.4727C6.93753 11.5402 6.86697 11.5948 6.78734 11.6327C6.70771 11.6706 6.62087 11.6909 6.53269 11.6923H6.52231C6.43605 11.6923 6.35077 11.6741 6.27199 11.639C6.1932 11.6039 6.12268 11.5526 6.065 11.4885L3.84962 9.02692C3.79335 8.96725 3.74959 8.89693 3.72089 8.8201C3.69219 8.74327 3.67914 8.66148 3.6825 8.57953C3.68587 8.49758 3.70558 8.41714 3.74047 8.34292C3.77537 8.2687 3.82476 8.2022 3.88572 8.14734C3.94668 8.09247 4.018 8.05035 4.09548 8.02345C4.17295 7.99654 4.25502 7.98539 4.33687 7.99065C4.41872 7.99592 4.49869 8.01749 4.57208 8.0541C4.64547 8.09071 4.7108 8.14162 4.76423 8.20385L6.50615 10.1392L11.2212 4.52731C11.3269 4.40503 11.4766 4.32928 11.6377 4.31644C11.7989 4.3036 11.9586 4.35469 12.0824 4.45868C12.2062 4.56266 12.2841 4.71119 12.2993 4.87215C12.3144 5.0331 12.2656 5.19356 12.1635 5.31885Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconSuccess;
