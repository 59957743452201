import { FunctionComponent, HTMLAttributes } from 'react';

const IconWarning: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M15.8957 13.9235L9.16018 1.41514C8.68277 0.528287 7.41098 0.528287 6.93317 1.41514L0.198015 13.9235C0.0943564 14.116 0.0423868 14.3322 0.0471788 14.5508C0.0519708 14.7694 0.113361 14.983 0.225356 15.1708C0.337352 15.3586 0.496126 15.5142 0.686182 15.6223C0.876237 15.7304 1.09108 15.7874 1.30974 15.7877H14.782C15.0009 15.7878 15.216 15.731 15.4063 15.623C15.5966 15.515 15.7557 15.3595 15.8679 15.1717C15.9801 14.9838 16.0417 14.77 16.0466 14.5513C16.0514 14.3325 15.9995 14.1162 15.8957 13.9235ZM8.04688 13.8512C7.89055 13.8512 7.73773 13.8048 7.60774 13.718C7.47776 13.6311 7.37645 13.5077 7.31662 13.3633C7.2568 13.2188 7.24115 13.0599 7.27164 12.9066C7.30214 12.7532 7.37742 12.6124 7.48797 12.5019C7.59851 12.3913 7.73935 12.316 7.89267 12.2855C8.046 12.255 8.20493 12.2707 8.34936 12.3305C8.49379 12.3903 8.61723 12.4917 8.70409 12.6216C8.79094 12.7516 8.83729 12.9044 8.83729 13.0608C8.83729 13.2704 8.75402 13.4715 8.60579 13.6197C8.45755 13.7679 8.25651 13.8512 8.04688 13.8512ZM8.90527 5.90156L8.67842 10.7231C8.67842 10.8908 8.6118 11.0517 8.49321 11.1702C8.37463 11.2888 8.21379 11.3554 8.04608 11.3554C7.87838 11.3554 7.71754 11.2888 7.59896 11.1702C7.48037 11.0517 7.41375 10.8908 7.41375 10.7231L7.1869 5.90353C7.1818 5.78836 7.19994 5.67335 7.24024 5.56534C7.28054 5.45733 7.34217 5.35854 7.42147 5.27486C7.50076 5.19118 7.59609 5.12432 7.70177 5.07827C7.80745 5.03221 7.92132 5.00791 8.0366 5.0068H8.0449C8.16096 5.00674 8.27583 5.0302 8.38258 5.07575C8.48933 5.12131 8.58574 5.18802 8.66599 5.27186C8.74625 5.3557 8.80869 5.45493 8.84954 5.56356C8.8904 5.67219 8.90881 5.78798 8.90369 5.90393L8.90527 5.90156Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconWarning;
